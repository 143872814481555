import React, { Component } from "react"
import { graphql } from "gatsby"

import { Layout, Seo, Hero } from "../components"

class Page extends Component {
  render() {
    const StaticPage = this.props.data.wordpressPage
    let featImage = ""
    if(StaticPage.featured_media && StaticPage.featured_media.localFile) {
      featImage = StaticPage.featured_media.localFile.childImageSharp.fluid
    }

    const socialImage = StaticPage.featured_media && StaticPage.featured_media.localFile && StaticPage.featured_media.localFile.childImageSharp.fixed
      ? StaticPage.featured_media.localFile.childImageSharp.fixed
      : null

    return (
      <Layout>
        <Seo title={StaticPage.title} pathname={this.props.location.pathname} image={socialImage} />
        <Hero image={featImage} title={StaticPage.title} />
        <section className="section has-background-white">
          <div className="container content">
            <div dangerouslySetInnerHTML={{ __html: StaticPage.content }} />
          </div>
        </section>
      </Layout>
    )
  }
}

export default Page

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      featured_media {
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
            fixed(width: 1200, height: 630) {
              src
              height
              width
            }
          }
        }
      }
    }
    site {
      id
      siteMetadata {
        title
        description
      }
    }
  }
`